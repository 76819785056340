import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import TableScheduleTeachingDetails from '../../../components/Elements/Table/Table-Schedule-Teaching-Details';
import { $api } from '../../../services/service';
import { useParams } from 'react-router';
import fileDownload from 'js-file-download';

function ScheduleTeachingDetails() {
    let history = useHistory();
    // @ts-ignore
    let { idSubjectTeaching } = useParams();
    const dispatch = useDispatch();
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });

    const [attendances, setAttendances] = useState([]);
    const [subject, setSubject] = useState({
        subjectId: '',
        subjectName: ''
    });
    useEffect(() => {
        async function getSubjectsDetail() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectTeachingsGetAttendances(idSubjectTeaching);
                if (res && res.result) {
                    setAttendances(res.result.attendances);
                    setSubject({
                        subjectId: res.result.subjectId,
                        subjectName: res.result.subjectName
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }
        //
        getSubjectsDetail();
    }, []);

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true,
            subTitle: msg
        });
    }

    async function exportsAttendance() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.exportAttendances(idSubjectTeaching);
            if (res) {
                // @ts-ignore
                fileDownload(res, `Chi_Tiet_Diem_Danh_Mon_${subject.subjectName}.xlsx`)
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <a href={`/thoi-khoa-bieu`}>Danh sách môn học</a>
                        <div className='___title__box__last'>{`>`}</div>
                        <a href={`/lich-hoc-mon?id=` + subject.subjectId}>Môn học: {subject.subjectName}</a>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>Chi tiết điểm danh</div>
                    </div>
                </div>


                {attendances.length === 0 ? (
                    <div
                        className='faculties--list flex flex-wrap -ml-4 -mb-4'
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <img
                            src='/notFound_01.png'
                            alt='Không tìm thấy dữ liệu'
                            title='Không tìm thấy dữ liệu'
                        />
                    </div>
                ) : (
                    <div className='w-auto-scroll'>
                        <div className={'d-flex justify-content-end'}>
                            <button className={'btn btn-success'} onClick={() => exportsAttendance()}><i className={'fas fa-file mr-1'}></i>Xuất chi tiết
                                điểm danh
                            </button>
                        </div>
                        <div className='table w-auto-scroll mt-8 w-full'>
                            <TableScheduleTeachingDetails
                                attendances={attendances}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default ScheduleTeachingDetails;
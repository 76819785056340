import axiosClient from 'config/axiosClient';

const _url = '/Api/Faculties';
const facultiesService = {
	getPaging: (option) => {
		return axiosClient.post(_url + '/GetPaging', option);
	},
	getDetail: (id) => {
		return axiosClient.post(_url + '/Get', { id: id });
	},
	createFaculties: (data) => {
		return axiosClient.post(_url + '/Create', data);
	},
	updateFaculties: (data) => {
		return axiosClient.post(_url + '/Update', data);
	},
	deleteFaculties: (id) => {
		return axiosClient.post(_url + '/Delete', { id: id });
	},
	downloadTemplate: () => {
		return axiosClient.get(_url + '/Template', {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type':
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
		});
	},
	importFaculties: (data) => {
		return axiosClient.post(_url + '/Import', data);
	},
	exportFaculties: () => {
		return axiosClient.get(_url + '/Export', {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type':
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
		});
	},
};

export default facultiesService;

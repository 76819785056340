import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from 'components/Elements/Button/Button';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Calendar from 'components/Elements/Calender/Calendar';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import DialogStudent from 'components/Elements/DialogStudent/DialogStudent';
import TableSubjectTeachingExam from '../../../components/Elements/Table/Table-Subject-Teaching-Exam';
import DragDropInput from '../../../components/Elements/Input/Drag-drop-input/Drag-drop-input';
import { Prompt } from 'react-router';

function Schedule__Subject__Teaching() {
    let history = useHistory();
    let { idSubjectTeaching } = useParams();
    const dispatch = useDispatch();
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [isDocumentChange, setIsDocumentChange] = useState(false);
    const [modalShowSubjectSchedule, setModalShowSubjectSchedule] = useState(false);
    const [modalShowSubjectTeachingExam, setModalShowSubjectTeachingExam] = useState(false);
    const [modalShowAddNotification, setModalShowAddNotification] = useState(false);
    const [modalShowAddStudent, setModalShowAddStudent] = useState(false);
    const [noti, setNoti] = useState({ message: '', enforceRead: false });
    const [updateSubjectSchedule, setUpdateSubjectSchedule] = useState(null);
    const [updateSubjectTeachingExam, setUpdateSubjectTeachingExam] = useState('');
    const [updateStudentSubjectSchedule, setUpdateStudentSubjectSchedule] = useState(null);
    const [subjectTeachingDetail, setSubjectTeachingDetail] = useState({
        id: ''
        , name: ''
        , subjectId: ''
        , subject: []
        , startDate: new Date()
        , endDate: new Date()
        , teacherId: ''
        , roomIdDefault: ''
        , teacher: []
    });
    const [subjectSchedule, setSubjectSchedule] = useState([]);
    const [subjectTeachingExamList, setSubjectTeachingExamList] = useState([]);
    const [subjectScheduleTmp, setSubjectScheduleTmp] = useState({
        id: '',
        subjectTeachingId: idSubjectTeaching,
        startDate: new Date(),
        endDate: new Date(),
        startTime: '07:30',
        endTime: '10:45',
        roomId: '',
        isRepeatedWeekly: false
    });

    const [subjectTeachingExam, setSubjectTeachingExam] = useState({
        id: '',
        subjectTeachingId: idSubjectTeaching,
        questionSuiteId: '',
        dateExam: new Date(),
        startTime: '07:00',
        endTime: '07:00',
        name: '',
        roomIdDefault: '',
        notes: '',
        type: 1,
        count: 1,
        numOfEasy: 0,
        numOfNormal: 0,
        numOfHard: 0
    });
    const [subjectTeachingExamUpdate, setSubjectTeachingExamUpdate] = useState({
        id: '',
        subjectTeachingId: idSubjectTeaching,
        startDate: new Date(),
        endDate: new Date(),
        startTime: '07:00',
        endTime: '07:00',
        name: '',
        roomIdDefault: '',
        notes: '',
        portion: 0
    });


    const [timeInput, setTimeInput] = useState({
        startTime: '07:00',
        endTime: '07:00'
    });

    const [data, setData] = useState({
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: '#1e3a8a',
        textColor: '#1e3a8a',
        eventName: 'subject'
    });

    const [dataExam, setDataExam] = useState({
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: '#red',
        eventName: 'exam'
    });

    const [changes, setChanges] = useState(false);
    const [documentLists, setDocumentLists] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [questionSuite, setQuestionSuite] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [examConfirmDialog, setExamConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [confirmDialogAddNotification, setConfirmDialogAddNotification] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [idDelete, setIdDelete] = useState(null);


    useEffect(() => {
        async function getSubjectTeachingDetail() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectTeaching_get(idSubjectTeaching);
                if (res && res.result) {
                    subjectTeachingDetail.id = res['result'].id;
                    subjectTeachingDetail.name = res['result'].name;
                    subjectTeachingDetail.subjectId = res['result'].subjectId;
                    subjectTeachingDetail.subject = res['result'].subject;
                    subjectTeachingDetail.startDate = res['result'].startDate;
                    subjectTeachingDetail.endDate = res['result'].endDate;
                    subjectTeachingDetail.teacherId = res['result'].teacherId;
                    subjectTeachingDetail.roomIdDefault = res['result'].roomIdDefault;
                    subjectTeachingDetail.teacher = res['result'].teacher;
                    setSubjectTeachingDetail(subjectTeachingDetail);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        // --------------------------------------------------------------------------------------------------------------------------
        async function getRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getAll();
                setRooms(res.result);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getSubjectTeachingExam() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.exam.subjectTeachingExam_get_all(idSubjectTeaching);
                setSubjectTeachingExamList(res.result['items']);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }


        getSubjectTeachingDetail();
        getSubjectTeachingExam();
        getRooms();
    }, []);

    const [subjectInfo, setSubjectInfo] = useState({});
    const [subjectDetail, setSubjectDetail] = useState({});
    const [teacherInfo, setTeacherInfo] = useState({});
    useEffect(() => {
        async function getSubjectTeachingDetail() {
            try {
                const res: any = await $api.schedule.subjectTeaching_get(idSubjectTeaching);
                setSubjectInfo(res['result']);
                setSubjectDetail(res['result'].subject);
                setTeacherInfo(res['result'].teacher);
                if (res['result']['numberOfStudent'] == 0) {
                    throw { custom_message: 'Lớp học chưa có sinh viên!' };
                }
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else if (e?.custom_message) {
                    errMsg = e?.custom_message;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getSubjectTeachingDetail();
    }, [changes]);
    useEffect(() => {
        // @ts-ignore
        async function getScheduleSubject(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectSchedule_getPaging(id);
                setSubjectSchedule(res['result'].items);
                const r = res.result?.items.map((item: any) => {
                    return {
                        ...item,
                        title: `<b>${moment(item.startDateTime + 'Z').format(
                            'HH:mm'
                        )} - ${moment(item.endDateTime + 'Z').format(
                            'HH:mm'
                        )}</b>
						<br/><span>${item?.room?.name} ${item.isCheckAttendance ? '- Đã điểm danh' : ''}</span>`,
                        start: moment(item.startDateTime + 'Z').format('YYYY-MM-DD'),
                        end: moment(item.endDateTime + 'Z').format('YYYY-MM-DD'),
                        backgroundColor: Number.parseInt(moment(item.startDateTime + 'Z').format('HH')) > 12 ? (item.isCheckAttendance ? '#f59e0b' : 'rgba(248,176,80,0.5)') : ((item.isCheckAttendance ? '#1e3a8a' : 'rgba(30,58,138,0.5)')),
                        eventName: 'subject'
                    };
                });
                // @ts-ignore
                setData(r);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getSubjectTeachingExam(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.exam.subjectTeachingExam_get_all(id);
                const r = res.result?.items.map((item: any) => {
                    return {
                        ...item,
                        title: `<b>${moment(item.startDate + 'Z').format(
                            'HH:mm'
                        )} - ${moment(item.endDate + 'Z').format(
                            'HH:mm'
                        )}</b>
						<br/><b>${'Lịch thi:' + item?.name}</b>`,
                        start: moment(item.startDate + 'Z').format('YYYY-MM-DD'),
                        end: moment(item.endDate + 'Z').format('YYYY-MM-DD'),
                        backgroundColor: '#c42828',
                        eventName: 'exam'
                    };
                });
                setDataExam(r);
                setSubjectTeachingExamList(res.result['items']);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (idSubjectTeaching && idSubjectTeaching?.length) {
            getScheduleSubject(idSubjectTeaching);
            getSubjectTeachingExam(idSubjectTeaching);
            // @ts-ignore
        }
    }, [changes]);
    useEffect(() => {
        getQuestionSuite();
    }, [subjectDetail, changes]);

    useEffect(() => {
        async function getDocument() {
            try {
                const res: any = await $api.subjectDocument.getPagingDocument({
                    subjectId: subjectDetail['id']
                });
                setDocumentLists(() => res['result']['items']);
                setIsDocumentChange(() => false);
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getDocument();
    }, [subjectDetail, isDocumentChange]);

    async function getQuestionSuite() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.getQuestionSuite(subjectDetail['id'], '');
            setQuestionSuite(res.result.items);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function isEventClicked(eventId, eventName) {
        try {
            if (eventName === 'exam') {
                dispatch(setLoading(true));
                const res: any = await $api.exam.subjectTeachingExam_get(eventId);
                const startTime = moment(res.result.startDate + 'Z').format('HH:mm');
                const endTime = moment(res.result.endDate + 'Z').format('HH:mm');
                setSubjectTeachingExam({
                    endTime: moment(res.result.endDate + 'Z').format('HH:mm'),
                    startTime: moment(res.result.startDate + 'Z').format('HH:mm'),
                    subjectTeachingId: res.result.subjectTeachingId,
                    id: res.result.id,
                    dateExam: res.result.startDate,
                    name: res.result.name,
                    notes: res.result.notes,
                    type: res.result.type,
                    count: res.result.count,
                    roomIdDefault: res.result.roomIdDefault,
                    questionSuiteId: res.result.questionSuiteId,
                    numOfEasy: res.result.numOfEasy,
                    numOfNormal: res.result.numOfNormal,
                    numOfHard: res.result.numOfHard
                });
                setTimeInput({
                    startTime: startTime,
                    endTime: endTime
                });
                setModalShowSubjectTeachingExam(true);
                setUpdateSubjectTeachingExam(eventId);
                setChanges(!changes);
                dispatch(setLoading(false));
            } else {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectSchedule_get(eventId);
                const startTime = moment(res.result.startDateTime + 'Z').format('HH:mm');
                const endTime = moment(res.result.endDateTime + 'Z').format('HH:mm');
                setSubjectScheduleTmp({
                    id: eventId,
                    subjectTeachingId: res.result.subjectTeachingId,
                    startDate: new Date(res.result.startDateTime + 'Z'),
                    endDate: new Date(res.result.startDateTime + 'Z'),
                    startTime: startTime,
                    endTime: endTime,
                    roomId: res.result.roomId,
                    isRepeatedWeekly: false
                });
                setTimeInput({
                    startTime: startTime,
                    endTime: endTime
                });
                setModalShowSubjectSchedule(true);
                setUpdateSubjectSchedule(eventId);
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function setDatWithTime(date, time) {
        const hour = time.split(':')[0] || 9;
        const min = time.split(':')[1] || 0;
        if ((Number.parseInt(time) - 7) >= 10) {
            return moment(date).format('YYYY-MM-DD') + 'T' + (Number(hour) - 7) + ':' + min + ':00.000Z';
        } else {
            return moment(date).format('YYYY-MM-DD') + 'T' + '0' + (Number(hour) - 7) + ':' + min + ':00.000Z';
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function isCreateSubjectSchdule(e) {
        e.stopPropagation();
        try {
            subjectScheduleTmp.id = '';
            subjectScheduleTmp.subjectTeachingId = subjectTeachingDetail.id;
            subjectScheduleTmp.startDate = subjectTeachingDetail.startDate;
            subjectScheduleTmp.endDate = subjectTeachingDetail.endDate;
            subjectScheduleTmp.startTime = '07:30';
            subjectScheduleTmp.endTime = '10:45';
            subjectScheduleTmp.roomId = subjectTeachingDetail.roomIdDefault;
            setSubjectScheduleTmp(subjectScheduleTmp);
            setTimeInput({
                startTime: '07:30',
                endTime: '10:45'
            });
            setUpdateSubjectSchedule(null);
            setModalShowSubjectSchedule(true);
        } catch (e) {
        }
    }

    async function isCreateSubjectTeachingExam(e) {
        e.stopPropagation();
        try {
            subjectTeachingExam.id = '';
            subjectTeachingExam.subjectTeachingId = subjectTeachingDetail.id;
            subjectTeachingExam.dateExam = new Date();
            subjectTeachingExam.startTime = '07:00';
            subjectTeachingExam.endTime = '07:00';
            subjectTeachingExam.notes = '';
            subjectTeachingExam.type = 1;
            subjectTeachingExam.count = 1;
            subjectTeachingExam.roomIdDefault = subjectTeachingDetail.roomIdDefault;
            setSubjectTeachingExam(subjectTeachingExam);
            setTimeInput({
                startTime: '07:00',
                endTime: '07:00'
            });
            setUpdateSubjectTeachingExam('');
            setModalShowSubjectTeachingExam(true);
        } catch (e) {
        }
    }

    async function onSaveSubjectSchedule() {
        if (!subjectScheduleTmp.isRepeatedWeekly) {
            if (setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        } else {
            if (setDatWithTime(subjectScheduleTmp.endDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        }

        try {
            // dispatch(setLoading(true));
            if (!updateSubjectSchedule) {
                const postData = {
                    subjectTeachingId: subjectScheduleTmp.subjectTeachingId,
                    startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                    endDateTime: setDatWithTime(subjectScheduleTmp.isRepeatedWeekly ? subjectScheduleTmp.endDate : subjectScheduleTmp.startDate, timeInput.endTime),
                    roomId: subjectScheduleTmp.roomId
                };

                if (subjectScheduleTmp.isRepeatedWeekly) {
                    const res: any = await $api.schedule.subjectSchedule_createRepeat(postData);
                    dispatch(
                        setAlert({
                            title: res.message
                        })
                    );
                } else {
                    const res: any = await $api.schedule.subjectSchedule_create([postData]);
                    dispatch(
                        setAlert({
                            title: res.message
                        })
                    );
                }
            } else {
                const postData = {
                    id: subjectScheduleTmp.id,
                    startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                    endDateTime: setDatWithTime(subjectScheduleTmp.isRepeatedWeekly ? subjectScheduleTmp.endDate : subjectScheduleTmp.startDate, timeInput.endTime),
                    roomId: subjectScheduleTmp.roomId
                };
                const res: any = await $api.schedule.subjectSchedule_Update(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            }
            setSubjectScheduleTmp({
                id: '',
                subjectTeachingId: idSubjectTeaching,
                startDate: new Date(),
                endDate: new Date(),
                startTime: '07:30',
                endTime: '10:45',
                roomId: subjectTeachingDetail.roomIdDefault,
                isRepeatedWeekly: false
            });
            setTimeInput({
                startTime: '07:30',
                endTime: '10:45'
            });
            setModalShowSubjectSchedule(false);
            setChanges(!changes);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function onSaveSubjectTeachingExam() {
        if (subjectTeachingExam.name === '') {
            show_ErrMsg('Nhập tên lịch thi');
            return;
        }
        if (!(subjectTeachingExam.count > 0 && subjectTeachingExam.count <= 4)) {
            show_ErrMsg('Lần thi không hợp lệ. Nhỏ nhất 1 và lớn nhất 4');
            return;
        }
        if (setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime) <= setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime)) {
            show_ErrMsg('Thời gian buổi thi không hợp lệ. Vui lòng kiểm tra lại');
            return;
        }


        try {
            // dispatch(setLoading(true));
            if (!updateSubjectTeachingExam) {
                const postData = {
                    'subjectTeachingId': subjectTeachingExam.subjectTeachingId,
                    'name': subjectTeachingExam.name,
                    'type': subjectTeachingExam.type,
                    'count': subjectTeachingExam.count,
                    'startDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime),
                    'endDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime),
                    'roomIdDefault': subjectTeachingExam.roomIdDefault,
                    'questionSuiteId': subjectTeachingExam.questionSuiteId,
                    'numOfEasy': subjectTeachingExam.numOfEasy,
                    'numOfNormal': subjectTeachingExam.numOfNormal,
                    'numOfHard': subjectTeachingExam.numOfHard,
                    'notes': subjectTeachingExam.notes
                };
                const res: any = await $api.exam.subjectTeachingExam_save(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            } else {
                const postData = {
                    'id': subjectTeachingExam.id,
                    'subjectTeachingId': subjectTeachingExam.subjectTeachingId,
                    'name': subjectTeachingExam.name,
                    'type': subjectTeachingExam.type,
                    'count': subjectTeachingExam.count,
                    'startDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime),
                    'endDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime),
                    'roomIdDefault': subjectTeachingExam.roomIdDefault,
                    'questionSuiteId': subjectTeachingExam.questionSuiteId,
                    'numOfEasy': subjectTeachingExam.numOfEasy,
                    'numOfNormal': subjectTeachingExam.numOfNormal,
                    'numOfHard': subjectTeachingExam.numOfHard,
                    'notes': subjectTeachingExam.notes
                };
                const res: any = await $api.exam.subjectTeachingExam_save(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            }
            setSubjectTeachingExam({
                id: '',
                subjectTeachingId: idSubjectTeaching,
                dateExam: new Date(),
                startTime: '07:00',
                endTime: '07:00',
                name: '',
                type: 1,
                count: 1,
                notes: '',
                roomIdDefault: subjectTeachingDetail.roomIdDefault,
                questionSuiteId: '',
                numOfEasy: 0,
                numOfNormal: 0,
                numOfHard: 0
            });
            setTimeInput({
                startTime: '07:00',
                endTime: '07:00'
            });
            setModalShowSubjectTeachingExam(false);
            setChanges(!changes);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function onSaveContinueSubjectSchedule() {
        if (!subjectScheduleTmp.isRepeatedWeekly) {
            if (setDatWithTime(subjectScheduleTmp.startDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        } else {
            if (setDatWithTime(subjectScheduleTmp.endDate, timeInput.endTime) <= setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime)) {
                show_ErrMsg('Thời gian buổi học không hợp lệ. Vui lòng kiểm tra lại');
                return;
            }
        }
        try {
            // dispatch(setLoading(true));
            const postData = {
                subjectTeachingId: subjectScheduleTmp.subjectTeachingId,
                startDateTime: setDatWithTime(subjectScheduleTmp.startDate, timeInput.startTime),
                endDateTime: setDatWithTime(subjectScheduleTmp.isRepeatedWeekly ? subjectScheduleTmp.endDate : subjectScheduleTmp.startDate, timeInput.endTime),
                roomId: subjectScheduleTmp.roomId
            };
            if (subjectScheduleTmp.isRepeatedWeekly) {
                const res: any = await $api.schedule.subjectSchedule_createRepeat(postData);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            } else {
                const res: any = await $api.schedule.subjectSchedule_create([postData]);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            }
            setSubjectScheduleTmp({
                ...subjectScheduleTmp,
                id: '',
                subjectTeachingId: idSubjectTeaching,
                // startDate: subjectTeachingDetail.startDate,
                // endDate: subjectTeachingDetail.startDate,
                startTime: '07:30',
                endTime: '10:45',
                roomId: subjectTeachingDetail.roomIdDefault
                // isRepeatedWeekly: false
            });
            setTimeInput({
                startTime: '07:30',
                endTime: '10:45'
            });
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function onSaveContinueSubjectTeachingExam() {

        if (subjectTeachingExam.name === '') {
            show_ErrMsg('Nhập tên lịch thi');
            return;
        }
        if (!(subjectTeachingExam.count > 0 && subjectTeachingExam.count <= 4)) {
            show_ErrMsg('Lần thi không hợp lệ. Nhỏ nhất 1 và lớn nhất 4');
            return;
        }
        if (setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime) <= setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime)) {
            show_ErrMsg('Thời gian buổi thi không hợp lệ. Vui lòng kiểm tra lại');
            return;
        }

        if (!updateSubjectTeachingExam) {
            const postData = {
                'subjectTeachingId': subjectTeachingExam.subjectTeachingId,
                'name': subjectTeachingExam.name,
                'type': subjectTeachingExam.type,
                'count': subjectTeachingExam.count,
                'startDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime),
                'endDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime),
                'roomIdDefault': subjectTeachingExam.roomIdDefault,
                'questionSuiteId': subjectTeachingExam.questionSuiteId,
                'numOfEasy': subjectTeachingExam.numOfEasy,
                'numOfNormal': subjectTeachingExam.numOfNormal,
                'numOfHard': subjectTeachingExam.numOfHard,
                'notes': subjectTeachingExam.notes
            };
            const res: any = await $api.exam.subjectTeachingExam_save(postData);
            dispatch(
                setAlert({
                    title: res.message
                })
            );
        } else {
            const postData = {
                'id': subjectTeachingExam.id,
                'subjectTeachingId': subjectTeachingExam.subjectTeachingId,
                'name': subjectTeachingExam.name,
                'type': subjectTeachingExam.type,
                'count': subjectTeachingExam.count,
                'startDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.startTime),
                'endDate': setDatWithTime(subjectTeachingExam.dateExam, timeInput.endTime),
                'roomIdDefault': subjectTeachingExam.roomIdDefault,
                'questionSuiteId': subjectTeachingExam.questionSuiteId,
                'numOfEasy': subjectTeachingExam.numOfEasy,
                'numOfNormal': subjectTeachingExam.numOfNormal,
                'numOfHard': subjectTeachingExam.numOfHard,
                'notes': subjectTeachingExam.notes
            };
            const res: any = await $api.exam.subjectTeachingExam_save(postData);
            dispatch(
                setAlert({
                    title: res.message
                })
            );
        }
        setSubjectTeachingExam({
            id: '',
            subjectTeachingId: idSubjectTeaching,
            dateExam: new Date(),
            startTime: '07:00',
            endTime: '07:00',
            name: '',
            type: 1,
            count: 1,
            notes: '',
            roomIdDefault: subjectTeachingDetail.roomIdDefault,
            questionSuiteId: '',
            numOfEasy: 0,
            numOfNormal: 0,
            numOfHard: 0
        });
        setTimeInput({
            startTime: '07:00',
            endTime: '07:00'
        });
        setChanges(!changes);
        dispatch(setLoading(false));
    }

    async function isDeleteSubjectSchedule() {
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        // @ts-ignore
        setIdDelete(subjectScheduleTmp.id);
    }

    async function isDeleteSubjectTeachingExam(id) {
        setExamConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        // @ts-ignore
        setIdDelete(id);
    }

    async function addNotificationSubject() {
        setConfirmDialogAddNotification({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_createNotification({
                'subjectTeachingId': subjectScheduleTmp.subjectTeachingId,
                'message': noti.message,
                'enforceRead': noti.enforceRead
            });
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setModalShowSubjectSchedule(false);
                setUpdateSubjectSchedule(null);
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedule_Delete(idDelete);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setModalShowSubjectSchedule(false);
                setUpdateSubjectSchedule(null);
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteExamItem() {
        setExamConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.exam.subjectTeachingExam_delete(idDelete);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setModalShowSubjectTeachingExam(false);
                setUpdateSubjectTeachingExam('');
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function isRepeatedWeekly_Checked(e) {
        setSubjectScheduleTmp({
            ...subjectScheduleTmp,
            isRepeatedWeekly: e.target.checked
        });
    }

    async function isAddStudentToSubjectSchedule(e) {
        e.stopPropagation();
        try {
            setUpdateStudentSubjectSchedule(null);
            setModalShowAddStudent(true);
        } catch (e) {
        }
    }

    async function createNotification() {
        if (noti.message.length) {
            // @ts-ignore
            setConfirmDialogAddNotification({
                isOpen: true,
                title: 'Xác nhận',
                subTitle: 'Bạn muốn gửi thông báo: "' + noti.message + '" Cho môn ' + `${subjectInfo['name']}` + '?'
            });
        } else {
            setErrDialog({
                isOpen: true,
                subTitle: 'Bạn chưa nhập nội dung thông báo!'
            });
        }
    }

    async function openUpdateExam(e, exam) {
        e.stopPropagation();
        try {
            setSubjectTeachingExam({
                id: exam.id,
                subjectTeachingId: exam.subjectTeachingId,
                dateExam: new Date(exam.startDate),
                endTime: moment(exam.endDate).format('HH:mm'),
                startTime: moment(exam.startDate).format('HH:mm'),
                name: exam.name,
                notes: exam.notes,
                type: exam.type,
                count: exam.count,
                roomIdDefault: exam.roomIdDefault,
                questionSuiteId: exam.questionSuiteId,
                numOfEasy: exam.numOfEasy,
                numOfNormal: exam.numOfNormal,
                numOfHard: exam.numOfHard
            });
            setTimeInput({
                endTime: moment(exam.endDate + 'Z').format('HH:mm'),
                startTime: moment(exam.startDate + 'Z').format('HH:mm')
            });
            setUpdateSubjectTeachingExam('update');
            setModalShowSubjectTeachingExam(true);
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function goAttendance(subjectSchedule) {
        history.push('/diem-danh?id=' + subjectSchedule.id);
    }

    function customPromptMessage() {
        show_ErrMsg('Lớp học chưa có sinh viên!');
        return false;
    }

    return (
        <DefaultLayout>
            <div className='faculties page'>
                <div className='page__header'>
                    <Prompt
                        when={subjectInfo['numberOfStudent'] == 0}
                        message={customPromptMessage}
                        // message={'Lớp học chưa có sinh viên!'}
                    />
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <Link to={`/thoi-khoa-bieu`}>Danh sách môn học</Link>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>
                            {
                                // @ts-ignore
                                // <a href={`/lich-hoc-mon?id=${subjectTeachingDetail['subject']?.id}`}>
                                // 	{
                                // 		// @ts-ignore
                                // 		subjectTeachingDetail['subject']?.name
                                // 	}
                                // </a>

                                // NhutTrung
                                <Link to={`/lich-hoc-mon?id=${subjectDetail['id']}`}>
                                    {

                                        subjectDetail['name']
                                    }
                                </Link>

                            }
                        </div>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>
                            {
                                // @ts-ignore
                                // subjectTeachingDetail?.name

                                // NhutTrung
                                subjectInfo['name']
                            }
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div>
                        <div className='flex justify-between items-end flex-wrap'>
                            <div className='action--item flex justify-start items-end'>
                                <div
                                    className='actions--item'
                                    onClick={(e) => {
                                        isCreateSubjectSchdule(e);
                                    }}>
                                    <Button
                                        label='Tạo thời khóa biểu'
                                        icon={<i className='fas fa-calendar-plus' />}
                                    />
                                </div>
                                <div
                                    className='actions--item'
                                    onClick={(e) => {
                                        isCreateSubjectTeachingExam(e);
                                    }}>
                                    <Button
                                        label='Tạo lịch thi'
                                        icon={<i className='fas fa-calendar-plus' />}
                                    />
                                </div>
                                <div
                                    className='actions--item'
                                    onClick={(e) => {
                                        isAddStudentToSubjectSchedule(e);
                                    }}>
                                    <Button
                                        label='Thêm Sinh viên'
                                        icon={<i className='fas fa-user-plus' />}
                                    />
                                </div>
                                <div className='actions--item' onClick={() => setModalShowAddNotification(true)}>
                                    <Button
                                        color={'bg-green-800'}
                                        hoverColor={'bg-green-900'}
                                        label='Thông báo môn học'
                                        icon={<i className='far fa-bell' />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-layout mt-8'>
                        <div className='tab-content'>
                            <Tabs defaultIndex={0}>
                                <TabList>
                                    <Tab>Lịch học</Tab>
                                    <Tab>Thông tin lịch học</Tab>
                                    <Tab>Lịch thi</Tab>
                                    <Tab>Tài liệu</Tab>
                                </TabList>
                                <TabPanel>
                                    <Calendar
                                        data={Array.prototype.concat(data, dataExam)}
                                        isEventClicked={(id, name) => isEventClicked(id, name)}
                                        isEventChangeMonthClicked={() => {
                                        }}
                                        isEventDateClicked={() => {
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <div className='py-4 px-2 max-w-md'>
                                        <div>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Tên lớp học
                                            </div>
                                            <input
                                                disabled
                                                type='text'
                                                autoComplete='subject'
                                                className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
                                                value={
                                                    // @ts-ignore

                                                    // NhutTrung
                                                    subjectInfo['name']
                                                }
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Môn học
                                            </div>
                                            <input
                                                disabled
                                                type='text'
                                                className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
                                                value={
                                                    // @ts-ignore

                                                    // NhutTrung
                                                    subjectDetail['name']
                                                }
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Giảng viên
                                            </div>
                                            <input
                                                disabled
                                                type='text'
                                                className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
                                                value={
                                                    // @ts-ignore
                                                    teacherInfo['fullName']
                                                }
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Số lượng sinh viên
                                            </div>
                                            <input
                                                disabled
                                                type='text'
                                                className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
                                                value={
                                                    // @ts-ignore
                                                    subjectInfo['numberOfStudent']
                                                }
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày bắt đầu
                                            </div>
                                            <DatePicker
                                                selected={new Date(subjectTeachingDetail.startDate)}
                                                dateFormat='dd/MM/yyyy'
                                                disabled
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày kết thúc
                                            </div>
                                            <DatePicker
                                                selected={new Date(subjectTeachingDetail.endDate)}
                                                dateFormat='dd/MM/yyyy'
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    {
                                        subjectTeachingExamList.length > 0 ?
                                            <div>
                                                <div className='flex justify-end items-center flex-wrap pt-2 pb-2'>
                                                    <div className='action--item flex justify-start items-end'>
                                                        <div
                                                            className='actions--item'
                                                            onClick={(e) => {
                                                                history.push(`/lich-thi-chi-tiet/${idSubjectTeaching}`);
                                                            }}>
                                                            <Button
                                                                label='Nhập điểm'
                                                                icon={<i className='fas fa-user-edit' />}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''
                                    }

                                    <TableSubjectTeachingExam
                                        data={subjectTeachingExamList}
                                        clicked={openUpdateExam}
                                        gotoResultExam={(e, id) => history.push(`/lich-thi-chi-tiet/${id}`)}
                                        deleteClicked={(e, id) => isDeleteSubjectTeachingExam(id)}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <DragDropInput documentLists={documentLists} subjectId={subjectDetail['id']}
                                                   handleChangeDocument={el => setIsDocumentChange(() => el)}></DragDropInput>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={modalShowSubjectSchedule}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setModalShowSubjectSchedule(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {updateSubjectSchedule
                            ? 'Cập nhật lịch học'
                            : 'Tạo mới lịch học'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        updateSubjectSchedule ? '' :
                            <div className='flex center'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Lặp lại hằng tuần
                                </div>
                                <input
                                    type='checkbox'
                                    className='h-4 w-4 mt-0.5 ml-4'
                                    // @ts-ignore
                                    checked={subjectScheduleTmp.isRepeatedWeekly}
                                    onChange={(e) => {
                                        isRepeatedWeekly_Checked(e);
                                    }}
                                />
                            </div>
                    }
                    <div>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            {subjectScheduleTmp.isRepeatedWeekly ? 'Từ ngày' : 'Ngày học'}
                        </div>
                        <DatePicker
                            selected={new Date(subjectScheduleTmp.startDate)}
                            onChange={(date) => {
                                setSubjectScheduleTmp({
                                    ...subjectScheduleTmp,
                                    startDate: date
                                });
                            }}
                            minDate={new Date(subjectTeachingDetail.startDate)}
                            dateFormat='dd/MM/yyyy'
                        />
                    </div>
                    {
                        !subjectScheduleTmp.isRepeatedWeekly ? '' :
                            <div className='mt-2'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Đến ngày
                                </div>
                                <DatePicker
                                    selected={new Date(subjectScheduleTmp.endDate)}
                                    onChange={(date) => {
                                        setSubjectScheduleTmp({
                                            ...subjectScheduleTmp,
                                            endDate: date
                                        });
                                    }}
                                    minDate={new Date(subjectTeachingDetail.startDate)}
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                    }

                    {rooms && rooms.length > 0 ? (
                        <div className='mt-2'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Phòng học
                            </div>
                            <select
                                className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                // @ts-ignore
                                value={subjectScheduleTmp.roomId}
                                onChange={(e) => {
                                    setSubjectScheduleTmp({
                                        ...subjectScheduleTmp,
                                        // @ts-ignore
                                        roomId: e.target.value
                                    });
                                }}>
                                {rooms.map((item: any) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    ) : (
                        ''
                    )}
                    <div style={{ paddingTop: '20px' }}>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            Giờ bắt đầu
                        </div>
                        <input
                            name='text'
                            type='time'
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Giờ bắt đầu buổi học'
                            onChange={(e) => {
                                setTimeInput({
                                    ...timeInput,
                                    startTime: e.target.value,
                                    endTime: e.target.value == '07:30' ? '10:45' : e.target.value == '13:30' ? '16:45' : timeInput.endTime
                                });
                            }}
                            value={timeInput.startTime}
                        />
                    </div>
                    <div style={{ paddingTop: '20px' }}>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            Giờ kết thúc
                        </div>
                        <input
                            name='text'
                            type='time'
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Giờ kết thúc'
                            onChange={(e) => {
                                setTimeInput({
                                    ...timeInput,
                                    endTime: e.target.value,
                                    startTime: e.target.value == '10:45' ? '07:30' : e.target.value == '16:45' ? '13:30' : timeInput.startTime
                                });
                            }}
                            min={timeInput.startTime}
                            value={timeInput.endTime}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    {
                        !updateSubjectSchedule ? '' :
                            <div className={'flex flex-row justify-between'}>
                                <div
                                    onClick={() => {
                                        isDeleteSubjectSchedule();
                                    }}>
                                    <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                            icon={<i className='fas fa-trash'></i>} label='Xóa buổi học' />
                                </div>
                                <div
                                    onClick={() => {
                                        goAttendance(subjectScheduleTmp);
                                    }}>
                                    <Button color={'bg-green-600'} hoverColor={'bg-green-800'}
                                            icon={<i className='fas fa-check'></i>} label='Điểm danh' />
                                </div>
                            </div>
                    }
                    <div>
                        <div
                            onClick={() => {
                                onSaveSubjectSchedule();
                            }}>
                            <Button icon={<i className='fas fa-save'></i>}
                                    label={updateSubjectSchedule ? 'Lưu lại' : 'Lưu & Đóng'} />
                        </div>
                    </div>
                    {
                        updateSubjectSchedule ? '' :
                            <div>
                                <div
                                    onClick={() => {
                                        onSaveContinueSubjectSchedule();
                                    }}>
                                    <Button icon={<i className='fas fa-save'></i>} label='Lưu & Tiếp tục' />
                                </div>
                            </div>
                    }

                </Modal.Footer>
            </Modal>
            <Modal
                show={modalShowSubjectTeachingExam}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setModalShowSubjectTeachingExam(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {updateSubjectTeachingExam
                            ? 'Cập nhật lịch thi'
                            : 'Tạo mới lịch thi'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'d-flex flex-row flex-wrap'}>
                        <div className={'col-12'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Tên lịch thi
                            </div>
                            <input type='text'
                                   className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                   value={subjectTeachingExam.name}
                                   onChange={e => setSubjectTeachingExam({
                                       ...subjectTeachingExam,
                                       name: e.target.value
                                   })}
                            />
                        </div>
                        <div className={'col-12'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Loại kỳ thi
                            </div>
                            <select
                                className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                // @ts-ignore
                                value={subjectTeachingExam.type}
                                onChange={(e) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        // @ts-ignore
                                        type: Number.parseInt(e.target.value)
                                    });
                                }}>
                                <option
                                    value={1}>
                                    Thường xuyên - Regular
                                </option>
                                <option
                                    value={2}>
                                    Giữa kỳ - Mid
                                </option>
                                <option
                                    value={3}>
                                    Cuối kỳ - Final
                                </option>
                            </select>
                        </div>
                        <div className='mt-2 col-12'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Bộ đề thi
                            </div>
                            <select
                                className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                // @ts-ignore
                                value={subjectTeachingExam.questionSuiteId}
                                onChange={(e) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        // @ts-ignore
                                        questionSuiteId: e.target.value
                                    });
                                }}>
                                <option
                                    value={''}>
                                    Chưa có bộ đề thi
                                </option>
                                {questionSuite && questionSuite.length > 0 ? questionSuite.map((item: any) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                }) : ''}
                            </select>
                        </div>

                        <div className={'col-6 mt-2'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Lần thi
                            </div>
                            <input type='number'
                                   min={1}
                                   max={4}
                                   className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                   value={subjectTeachingExam.count}
                                   onChange={e => setSubjectTeachingExam({
                                       ...subjectTeachingExam,
                                       count: Number.parseInt(e.target.value)
                                   })}
                            />
                        </div>
                        {rooms && rooms.length > 0 ? (
                            <div className='mt-2 col-6'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Phòng thi
                                </div>
                                <select
                                    className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                    // @ts-ignore
                                    value={subjectTeachingExam.roomIdDefault}
                                    onChange={(e) => {
                                        setSubjectTeachingExam({
                                            ...subjectTeachingExam,
                                            // @ts-ignore
                                            roomIdDefault: e.target.value
                                        });
                                    }}>
                                    {rooms.map((item: any) => {
                                        return (
                                            <option
                                                value={item.id}
                                                key={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={'col-6'}>
                            <div className='pl-1 mt-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Số câu dễ
                            </div>
                            <input
                                type='number'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Nhập số câu dễ'
                                value={subjectTeachingExam.numOfEasy}
                                onChange={(e) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        numOfEasy: Number.parseInt(e.target.value)
                                    });
                                }}
                            />
                        </div>
                        <div className={'col-6'}>
                            <div className='pl-1 mt-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Số câu trung bình
                            </div>
                            <input
                                type='number'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Nhập số câu trung bình'
                                value={subjectTeachingExam.numOfNormal}
                                onChange={(e) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        numOfNormal: Number.parseInt(e.target.value)
                                    });
                                }}
                            />
                        </div>
                        <div className='col-6'>
                            <div className='pl-1 mt-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Số câu khó
                            </div>
                            <input
                                type='number'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Nhập số câu khó'
                                value={subjectTeachingExam.numOfHard}
                                onChange={(e) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        numOfHard: Number.parseInt(e.target.value)
                                    });
                                }}
                            />
                        </div>
                        <div className='col-6 mt-2'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ngày thi
                            </div>
                            <DatePicker
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                selected={new Date(subjectTeachingExam.dateExam)}
                                onChange={(date) => {
                                    setSubjectTeachingExam({
                                        ...subjectTeachingExam,
                                        dateExam: date
                                    });
                                }}
                                // minDate={new Date()}
                                dateFormat='dd/MM/yyyy'
                            />
                        </div>
                        <div className={'col-6 mt-2'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Giờ bắt đầu
                            </div>
                            <input
                                name='text'
                                type='time'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Giờ bắt đầu thi'
                                onChange={(e) => {
                                    setTimeInput({
                                        ...timeInput,
                                        startTime: e.target.value
                                    });
                                }}
                                value={timeInput.startTime}
                            />
                        </div>
                        <div className={'col-6 mt-2'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Giờ kết thúc
                            </div>
                            <input
                                name='text'
                                type='time'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Giờ kết thúc'
                                onChange={(e) => {
                                    setTimeInput({
                                        ...timeInput,
                                        endTime: e.target.value
                                    });
                                }}
                                min={timeInput.startTime}
                                value={timeInput.endTime}
                            />
                        </div>
                        <div className={'col-12'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ghi chú
                            </div>
                            <input type='text'
                                   className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                   value={subjectTeachingExam.notes}
                                   onChange={e => setSubjectTeachingExam({
                                       ...subjectTeachingExam,
                                       notes: e.target.value
                                   })}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    {
                        !updateSubjectTeachingExam ? '' :
                            <div>
                                <div
                                    onClick={() => {
                                        isDeleteSubjectTeachingExam(subjectTeachingExam.id);
                                    }}>
                                    <Button icon={<i className='fas fa-trash'></i>} label='Xóa buổi thi' />
                                </div>
                            </div>
                    }
                    <div>
                        <div
                            onClick={() => {
                                onSaveSubjectTeachingExam();
                            }}>
                            <Button icon={<i className='fas fa-save'></i>}
                                    label={updateSubjectSchedule ? 'Lưu lại' : 'Lưu & Đóng'} />
                        </div>
                    </div>
                    {
                        updateSubjectTeachingExam ? '' :
                            <div>
                                <div
                                    onClick={() => {
                                        onSaveContinueSubjectTeachingExam();
                                    }}>
                                    <Button icon={<i className='fas fa-share-all'></i>} label='Lưu & Tiếp tục' />
                                </div>
                            </div>
                    }

                </Modal.Footer>
            </Modal>
            <Modal
                size='lg'
                show={modalShowAddStudent}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setModalShowAddStudent(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Danh sách sinh viên
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DialogStudent reloadData={(e) => setChanges(e)} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalShowAddNotification}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => {
                    setModalShowAddNotification(false);
                    setNoti({ message: '', enforceRead: false });
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        <i className='far fa-bell' /> Thêm thông báo cho môn học
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            <i className={'far fa-pencil'} /> Nội dung thông báo
                        </div>
                        <textarea
                            name='text'
                            maxLength={100}
                            rows={5}
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Nhập nội dung thông báo'
                            value={noti.message}
                            onChange={(e) => {
                                setNoti({ ...noti, message: e.target.value });
                            }}
                        />
                    </div>
                    <div className='mt-2 flex center'>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600'>
                            Thông báo quan trọng
                        </div>
                        <input
                            name={'enforceRead'}
                            type='checkbox'
                            className='h-4 w-4 mt-0.5 ml-4'
                            onChange={(e) => {
                                setNoti({
                                    ...noti,
                                    // @ts-ignore
                                    enforceRead: e.target.checked
                                });
                            }}
                            // @ts-ignore
                            checked={noti.enforceRead}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => {
                        setModalShowAddNotification(false);
                        setNoti({ message: '', enforceRead: false });
                    }}>
                        <Button
                            label={'Hủy'}
                            color={'bg-red-800'}
                            hoverColor={'bg-red-900'}
                            icon={<i className='far fa-times-circle' />}
                        />
                    </div>
                    <div
                        onClick={() => {
                            createNotification();
                        }}>
                        <Button
                            color={'bg-green-800'}
                            hoverColor={'bg-green-900'}
                            icon={<i className={'far fa-bell'} />}
                            label={'Tạo mới'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />
            <ConfirmDialog
                confirmDialog={examConfirmDialog}
                setConfirmDialog={setExamConfirmDialog}
                deleteItem={deleteExamItem}
            />
            <ConfirmDialog
                confirmDialog={confirmDialogAddNotification}
                setConfirmDialog={setConfirmDialogAddNotification}
                deleteItem={addNotificationSubject}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Schedule__Subject__Teaching;

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Parser from 'html-react-parser';
import './calendar.scss';
import { useEffect, useState } from 'react';

export default function Calendar(props) {
    const [events, setEvents] = useState([
        {
            title: '<b>Giờ học: 12:00 - 14:00</b><br/>Nguyễn Thị A<br/>Phòng học: 204',
            start: '2021-09-14T12:00',
            end: '2021-09-14T14:00'
        },
        {
            title: '<b>Giờ học: 9:00 - 12:00</b><br/>Nguyễn Thị A<br/>Phòng học: 204',
            start: '2021-09-14T09:00',
            end: '2021-09-14T12:00'
        },
        {
            title: '<b>Giờ học: 12:00 - 15:00</b><br/>Nguyễn Thị A<br/>Phòng học: 204',
            start: '2021-09-15T12:00',
            end: '2021-09-15T15:00'
        }
    ]);

    useEffect(() => {
        if (props.data) {
            setEvents(props.data);
        }
    }, [props.data]);

    const [headerToolbar, setHeaderToolbar] = useState({
        left: 'today timeGridWeek dayGridMonth',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
    });

    useEffect(() => {
        const toDayButton = document.querySelector('.fc-today-button');
        const dayGridMonthButton = document.querySelector(
            '.fc-dayGridMonth-button'
        );
        const dayGridWeekButton = document.querySelector(
            '.fc-timeGridWeek-button'
        );
        if (toDayButton && dayGridMonthButton && dayGridWeekButton) {
            toDayButton.innerHTML = 'Hôm Nay';
            dayGridWeekButton.innerHTML = 'Lịch Tuần';
            dayGridMonthButton.innerHTML = 'Lịch Tháng';
        }
    }, []);

    function eventClicked(e) {
        e.jsEvent.preventDefault();
        return props.isEventClicked(e?.event?.id, e.event._def.extendedProps.eventName);
    }

    function handleMonthChange(payload) {
        return props.isEventChangeMonthClicked(payload.startStr, payload.endStr);
    }

    function handleDateClick(event) {
        return props.isEventDateClicked(event.dateStr);
    }

    return (
        <FullCalendar
            // @ts-ignore
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView='dayGridMonth'
            locale='vi'
            firstDay={1}
            events={events}
            eventContent={renderEventContent}
            headerToolbar={headerToolbar}
            eventClick={eventClicked}
            datesSet={handleMonthChange}
            dateClick={handleDateClick}
        />
    );
}

function renderEventContent(eventInfo) {
    return <div className='event-item'
                style={{ backgroundColor: eventInfo.event.backgroundColor }}>{Parser(eventInfo.event.title)}</div>;
}
